import React, { useState } from 'react';
import parseDate from '../utils/parseDate';
import { graphql } from "gatsby"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import '../theme/type.scss';
import CustomFooter from '../components/CustomFooter';
import GdprCookies from '../components/GdprCookies';

import "../theme/layout.scss"
import ModalSuscribe from '../components/layout/ModalSuscribre';
import HeaderLayout from '../components/layout/HeaderLayout';
import { HeadApi } from '../components/HeadApi';

export const Head = ({data}) => {
  const post = data.contentfulComentarioSemanal
  // console.log("[POST HEAD]", post);
  return(
    <HeadApi />
  );
};

const Comentario = ({data}) =>{
    // console.log(data.contentfulComentarioSemanal)
    const Comentario = data.contentfulComentarioSemanal

    // console.log(data.comentario)
  //functions modal suscribe
  const [open, setOpen] = useState(false);
  function openSuscribe(){
    setOpen(true);
  }
  function closeSuscribe(){
    setOpen(false);
  }

    return (
      <>
        <HeaderLayout mb={true} ticker={false} openModal={openSuscribe}/>
        {/* <Layout newHeader={true}> */}
        <div className='wrap-content'>
            <div className='header--section'>
                <h2 className='title--section'>Comentario semanal</h2>
            </div>
            <div className="item">
                <h2 className="title"><b>{Comentario.titulo}</b></h2>
                <div className="resume-content">{Comentario.texto.raw ? renderRichText(Comentario.texto):Comentario.texto}</div>
                <p className="date">{Comentario.autor && Comentario.autor?.nombre}</p>
                <p className="date">{parseDate(Comentario.createdAt)}</p>
            </div>
        </div>
        {/* </Layout> */}
        <CustomFooter openModal={openSuscribe}/>
        <GdprCookies />
        {
          open && <ModalSuscribe closeModal={closeSuscribe}/>
        }
      </>
    )
}

export default Comentario

export const pageQuery = graphql`
    query($slug: String!) {
        contentfulComentarioSemanal(slug: { eq: $slug }) {
            ...comentarioSemanalPreview
        }
    }
`;
